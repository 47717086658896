import React from "react";
import "./App.css";

import About from "./screen/About"
import Menu from "./screen/menu"
import Photo from "./screen/photo"
import Music from "./screen/music"
import Video from "./screen/video"
import Shop from "./screen/shop"
import Wiooh from './screen/wiooh'

class App extends React.Component {
  render() {
    return (
      <div>
        <Menu />
        <About />
        <Photo />
        <Music />
        <Video />
        <Shop />
        <Wiooh />
      </div>
    );
  }
}

export default App;
