import React from 'react'
import Gallery from 'react-grid-gallery';

export default class ModalLibrary extends React.Component {

    getOptionImage(item) {
        return {
            src: item.src ? item.src : '',
            thumbnail : item.src ? item.src : '',
            caption: item.caption ? item.caption : '',
            thumbnailWidth: 320,
            thumbnailHeight: 174,
        }
    }

    getImages() {
        if(this.props.images) {
            return this.props.images.map((item) => {
                return this.getOptionImage(item);
            })
        }
        return [];
    }

    render() {
        return (
            <Gallery 
                images                 = {this.getImages()} 
                isOpen                 = {this.props.isOpen} 
                lightboxWillClose      = {this.props.closed}
                showLightboxThumbnails = {true}
                currentImage           = {this.props.currentImage}
                backdropClosesModal    = {true}
                />
        )
    }
}