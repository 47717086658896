import React from "react";
import Carousel from "./carousel";

export default class MusicAlbums extends React.Component {
  render() {
    return (
      <div className="container" >
        <Carousel />
      </div>
    );
  }
}
