import React from 'react'
import ReactPlayer from 'react-player'

export default class Player extends React.Component
{
    constructor(props) {
        super(props)
        this.state = {
            seeking : true,
        }
    }
    ref = player => {
        this.player = player;
        this.props.reff(player);
    }

    handleSeekMouseUp = e => {
        this.player.seekTo(parseFloat(e.target.value))
    }

    handleProgress = state => {
        this.props.setPlayed(state.played);
    }

    handleDuration = (duration) => {
        this.props.setDuraion(duration);
    }

    handleSeekChange = e => {
        this.setState({ played: parseFloat(e.target.value) })
    }

    render () {
        return (
            <ReactPlayer 
                ref          = {this.ref}
                style        = {{ display : "none" }}
                className    = 'react-player'
                width        = '100%'
                height       = '100%'
                url          = {this.props.url}
                pip          = {true}
                playing      = {this.props.playing}
                controls     = {false}
                light        = {false}
                loop         = {true}
                playbackRate = {1}
                volume       = {1}
                muted        = {false}
                onProgress   = {this.handleProgress}
                onDuration   = {this.handleDuration}
            />
            // <ReactPlayer url= {this.props.url}/>
        )
    }
}