import React from 'react'
import './style.css'
import NavbarCollapse from './navbarCollapse'

export default class Menu extends React.Component
{
    render() {
        return (
            <div>
                <div className="container-fluid fixed-top" id="menu">
                    <nav className="navbar navbar-expand-sm navbar-dark  container" >
                    
                    <a className="navbar-brand">
                        <img src="/images/svg/selena-svg-1.svg" className="logo" alt="" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapsibleNavbar"
                    >
                        <i className="fa fa-bars" aria-hidden="true"></i>
                    </button>
                    <NavbarCollapse />
                    </nav>
                </div>
                <div className="margin-menu"></div>
            </div>
        )
    }
}