import React from "react";
import HeaderText from "../../components/header-text";
import './style.css'

class Video extends React.Component {
  render() {
    return (
      <div id="video" className="container">
        <HeaderText text="enjoy my video!"/>
        <div>
          <div className="row">

            <div className="col-sm-6 col-xs-12 well img1" >
              <a data-fancybox="gallery" title="Boyfriend" href="https://www.youtube.com/watch?v=tSIk1QvIM2E">
                <img src="/images/video/selena-video-1.png" />
              </a>
            </div>

            <div className="col-sm-6 col-xs-12 well img2" >
              <a data-fancybox="gallery" title="Rare" href="https://www.youtube.com/watch?v=ia1iuXbEaYQ">
                <img src="/images/video/selena-video-2.png" />
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col-12 well" > 
              <a data-fancybox="gallery" title="Lose you to love me" href="https://www.youtube.com/watch?v=zlJDTxahav0">
                <img
                  src="/images/video/selena-video-3.png"
                  className="img-fluid"
                ></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Video;
