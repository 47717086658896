import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ItemAlbum from './item-album'

const imageAlbums = [
  {
    src: "./images/music/selena-music-1.png",
    title: "Rare (Deluxe)",
    year : 2020
  },
  {
    src: "./images/music/selena-music-2.png",
    title: "Revival (Deluxe)",
    year : 2015
  },
  {
    src: "./images/photo/selena-photo-1.jpg",
    title: "Rare (Deluxe)",
    year : 2020
  },
  {
    src: "./images/photo/selena-photo-2.jpg",
    title: "Revival (Deluxe)",
    year : 2015
  },
];
export default class DemoCarousel extends React.Component {
  renderphoto = (image, index) => {
    return (
      <div key={index} className="col-6">
        <ItemAlbum 
          src = {image.src}
          title = {image.title}
          year = {image.year}
        />
      </div>
    );
  };

  renderGroupPhoto = (imageAlbums, key) => {
    return (
      <div key={key} className="row">
        {imageAlbums.map((image, index) => {
          return this.renderphoto(image, index);
        })}
      </div>
    );
  };

  renderHtmlItem(i,num) {
    var data = [];
    var length = imageAlbums.length;
    for(var j=i; j < i + num && j < length; j++) {
      data.push(this.renderphoto(imageAlbums[j], j));
    }
    return data.map((item) => item);
  }

  renderHtmlCarousel(num) {
    var data = [];
    var length = imageAlbums.length;

    for(var i=0; i < (length/num); i++) {
        data.push((
          <div key={i} className="row">
            {this.renderHtmlItem(i*num,num)}
          </div>
        ));
      }
    return data.map((item) => item);
    }

  render() {
    return (
      <Carousel showArrows={false}  showThumbs={false}>
        {this.renderHtmlCarousel(2)}
      </Carousel>
    );
  }
}
