import React from 'react'
import './style.css'

export default class Wiooh extends React.Component
{
    render () {
        return (
            <div className="text-center" id="wiooh">
                <h1>Designed by <a href="https://wiooh.com/" alt="website wiooh.com" target="_blank">WiOOH</a></h1>
            </div>
        )
    }
}