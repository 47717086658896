import React from 'react'

export default class NavbarCollapse extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            data : [
                {
                    href : '#about',
                    name : 'About',
                },
                {
                    href : '#photo',
                    name : 'Photo',
                },
                {
                    href : '#music',
                    name : 'Music',
                },
                {
                    href : '#video',
                    name : 'Video',
                },
                {
                    href : '#shop',
                    name : 'Shop',
                },
                
            ]
        }
    }

    renderItemLi(item,index) {
        return (
            <li key={index} className="nav-item">
                <a className="nav-link" href={item.href}>
                    {item.name}
                </a>
            </li>
        )
    }

    renderLi() {
        return this.state.data.map((item,index) => {
            return this.renderItemLi(item,index);
        });
    }

    render () {
        return (
            <ul
                className="collapse navbar-collapse navbar-nav ml-auto"
                id="collapsibleNavbar"
            >
                { this.renderLi() }
            </ul>
        )
    }
}