import React from "react"
import './style.css'
export default class HeaderText extends React.Component
{
    render() {
        return (
            <div>
                <div className="header-text-0707">   
                    <h1>{this.props.text}</h1>
                    <br/>
                    <span className="line-red" />
                </div>
                <div className="margin-header-text"></div>
            </div>
        )
    }
}