import React from 'react'
import ModelLibrary from '../../components/ModelLibrary'

export default class PhotoImage extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            isOpen       : false,
            currentImage : 0,
            images       : [
                { src : "/images/photo/selena-photo-1.jpg" },
                { src : "/images/photo/selena-photo-2.jpg" },
                { src : "/images/photo/selena-photo-3.jpg" },
            ]
        }
    }
    
    openModel () {
        if(this.state.isOpen) {
            return <ModelLibrary 
                images       = {this.state.images}
                isOpen       = {true}
                currentImage = {this.state.currentImage}
                closed       = {() => {this.setState({isOpen:false})}}
                
            />
        }
        return  null;
    }
    setOpen = (currentImage) => {
        this.setState({ 
            isOpen : true ,
            currentImage : currentImage
        });
    }
    render () {
        return (
            <div className="row photo-image-0707" >
                <div className="col-12">
                    <img src="/images/photo/selena-photo-1.jpg" onClick={() => this.setOpen(0)}/>
                </div>
                <div className="col-8 img-1">
                    <img src="/images/photo/selena-photo-2.jpg" onClick={() => this.setOpen(1)}/>
                    <div className="photo-text">
                        <div>
                            <span />
                            <p>"My photos express a lot of me!"<br/>
                            I told my stories through my eyes"
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-4 img-2">
                    <img src="/images/photo/selena-photo-3.jpg" onClick={() => this.setOpen(2)}/>
                    <div className="box-link">
                        <a href="#" className="link-a-selena"> See more >>> </a>
                    </div>
                </div>
                {this.openModel()}
            </div>
        )
    }
}