import React from "react";
import HeaderText from "../../components/header-text";
import ItemShop from "./itemShop/itemShop"
import ModelLibrary from '../../components/ModelLibrary'
import './style.css'

class Shop extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isOpen       : false,
      currentImage : 0,
      data : [
        {
          src : '/images/shop/selena-shop-1.png',
          title : 'Beauty set 1',
        },
        {
          src : '/images/shop/selena-shop-2.png',
          title : 'Cushion 24-hour matte',
        },
        {
          src : '/images/shop/selena-shop-5.png',
          title : 'Miss Beauty - 2019 version',
        },
        {
          src : '/images/shop/selena-shop-4.png',
          title : 'Sweetie Lipstick',
        },
        {
          src : '/images/shop/selena-shop-3.png',
          title : 'Eye cream Brightening',
        },
        {
          src : '/images/shop/selena-shop-6.png',
          title : 'Tone up Essence',
        },
      ]
    }
  }

  openModel () {
    if(this.state.isOpen) {
        return <ModelLibrary 
            images       = {this.state.data}
            isOpen       = {true}
            currentImage = {this.state.currentImage}
            closed       = {() => {this.setState({isOpen:false})}}
            
        />
    }
    return  null;
}
setOpen = (currentImage) => {
    this.setState({ 
        isOpen : true ,
        currentImage : currentImage
    });
}

  renderHtmlShop() {
    return this.state.data.map((item,index) => {
      return (
        <div key={index} className="col-lg-4 col-6 box-col-img">
          <ItemShop  {...item} onClickProps={() => {this.setOpen(index)}}/>
        </div>
      )
    })
  }

  render() {
    return (
      <div id="shop" className="container">
        <HeaderText text="shop"/>
        <div className="container">
          <div className="row">
            {this.renderHtmlShop()}
          </div>
        </div>
        {this.openModel()}
      </div>
    );
  }
}
export default Shop;
