import React from 'react'
import "./style.css";
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

export default class ItemPlay extends React.Component
{
    constructor(props) {
        super(props) 
        this.state = {
            played : 0,
            duration : 0,
            playing : false,
        }
    }
    // setDuraion(duration) {
    //     this.setState({duration : duration});
    // }

    // handleSeekChange = e => {
    //     this.setState({ played: parseFloat(e.target.value) })
    // }

    handleOnChange = value => {
        //this.setState({ played: parseFloat((value / 100)) })
        this.props.playedChange(parseFloat((value / 100)));
    }

    // handleSeekMouseUp = e => {
    //     this.player.seekTo(parseFloat(e.target.value));
    // }

    onChangeComplete = value => {
        //this.player.seekTo(parseFloat(this.state.played));
        this.props.onChangeComplete();
    }

    // ref = player => {
    //     this.player = player;
    // }

    // handleProgress = state => {
    //     this.setState({
    //         played : this.player.getCurrentTime(),
    //     })
    // }

    getStringNumber(num) {
        num = Math.round(num)
        return num < 10 ? "0" + num : num;
    }

    getDurationString(num) {
        var duration = Math.round(num) ;
        return this.getStringNumber(duration/60) + ":" + this.getStringNumber(duration%60); 
    }

    format = (value) => {
        return this.getDurationString(value * this.props.duration / 100);
    }

    render() {
        return (
            <div className="row">
                <div className="col-2">
                    {
                        this.props.playing 
                        ? <img src="/images/svg/pause.svg" onClick={() => this.props.onIconClick(false)}/> 
                        : <img src="/images/svg/play3.svg" onClick={() => this.props.onIconClick(true)}/>
                    }
                </div>
                <div className="col-10">
                    <div className="row">
                        <div className={this.props.playing ? "col-10 title0907 active" : "col-10 title0907"}>
                            <span>{this.props.title}</span>
                        </div>
                        <div className="col-2">
                            <span>{this.getDurationString(this.props.duration)}</span>
                        </div>
                        <div className="col-12">
                            <div className='volume-slider'>
                                <Slider
                                    min         = {0}
                                    max         = {100}
                                    value       = {(this.props.played * 100)}
                                    orientation = 'horizontal'
                                    onChange    = {this.handleOnChange}
                                    onChangeComplete = {this.onChangeComplete}
                                    format      = {this.format}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}