import React, { Component } from "react";
import ReactPlayer from "react-player";
import Duration from "./duration";
import PlayList from './play-list'

export default class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data : [
        {
          url   : "https://www.youtube.com/watch?v=zlJDTxahav0",
          title : "Lose You To Love Me",
          duration : 207,
        },
        {
          url   : "https://www.youtube.com/watch?v=8u-_64S7plI",
          title : "Look At Her Now",
          duration : 163,
        },
        {
          url   : "https://www.youtube.com/watch?v=EgT_us6AsDg",
          title : "Love You Like A Love Song",
          duration : 220,
        },
        {
          url   : "https://www.youtube.com/watch?v=ij_0p_6qTss",
          title : "The Heart Wants What It Wants",
          duration : 275,
        },
        {
          url   : "https://www.youtube.com/watch?v=9h30Bx4Klxg",
          title : "Same Old Love",
          duration : 264,
        },
        {
          url   : "https://www.youtube.com/watch?v=n-D1EB74Ckg",
          title : "Come & Get It",
          duration : 289,
        },
        {
          url   : "https://www.youtube.com/watch?v=M8uPvX2te0I",
          title : "A Year Without Rain",
          duration : 208,
        },
      ]
    }
  }
 
  render() {
    return (
      <PlayList data={this.state.data}/>
    );
  }
}
