import React from 'react'
import './style.css'

export default class ItemShop extends React.Component
{

    render() {
        return (
            <div className="card item-shop-0807">
                <img src={this.props.src} onClick={this.props.onClickProps}/>
                <div className="card-body text-center">
                    <div className="card-title">{this.props.title}</div>
                </div>
            </div>
        )
    }
}