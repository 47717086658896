import React from 'react'
import './style.css'

export default class ItemAlbum extends React.Component
{
    render () {
        return (
            <div className="card" className="item-album-0807">
                <div className="music-album">
                    <img
                        className="card-img-top"
                        src={this.props.src}
                        alt="album selena"
                    />
                </div>
                <div className="row">
                    <div className="col-9">
                        <div className="card-body">
                            <h4 className="card-title">{this.props.title}</h4>
                            <p>
                                Album 
                                <i className="fa fa-circle" ></i>
                                {this.props.year}
                            </p>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="flex-container text-right">
                            <i className="fa fa-play-circle" aria-hidden="false" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}