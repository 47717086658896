import React from "react";
import HeaderText from "../../components/header-text";
import PhotoImage from './photoImage'
import './style.css'

class Photo extends React.Component {
  render() {
    return (
      <div id="photo" className="container" style={{ paddingTop: "10%" }}>
        <HeaderText text="WATCH ME!"/>
        <PhotoImage />
      </div>
    );
  }
}
export default Photo;
