import React from "react";
import Player from "./playerComponent";

// Only loads the YouTube player

export default class MusicPlaylists extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    // let url = "/mp3/Aiduaemve-Damvinhhung.mp3";
    return (
      <div className="container" >
        <div className="row">
          <div className="col-md-6 well">
            <img
              src="/images/music/selena-playlist-1.jpg"
              alt=""
              style={{ width: "100%" }}
            ></img>
          </div>
          <div className="col-md-6 well">
            <Player />
          </div>
        </div>
      </div>
    );
  }
}

