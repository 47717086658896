import React from "react";
import './style.css'

class About extends React.Component {
  render() {
    return (
      <div id="about" className="container-fluid">
        <div className="bg-about">
          <div className="container">
            <div className="row bg-img-about">
              <div className="col-5 bg-text">
                  <img src="/images/svg/selena-svg-2.svg" />
                  <button > BOOK ME NOW</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default About;
