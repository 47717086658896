import React from "react";
import MusicAlbums from "./musicAlbums";
import MusicPlaylists from "./musicPlaylists";
import HeaderText from "../../components/header-text";
import './style.css'

class Music extends React.Component {
  constructor(props) {
    super(props);
    this.state = { musicstate: 1 };
  }
  setMusicState = (musicstate) => {
    this.setState({ musicstate: musicstate });
  };
  active = (musicstate, musicactive) => {
    if (musicstate === musicactive) {
      return "active";
    } else {
      return null;
    }
  };
  renderMusic = (musicstate) => {
    if (musicstate == 1) {
      return <MusicAlbums />;
    } else if (musicstate == 2) {
      return <MusicPlaylists />;
    }
  };
  render() {
    return (
      <div id="music" className="container" >
        <HeaderText text="LISTEN TO ME"/>
        <nav className="navbar navbar-expand-sm bg-dark justify-content-center">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className = "nav-link"
                id        = {this.active(this.state.musicstate, 1)}
                onClick   = {() => this.setMusicState(1)}
              >
                ALBUMS
              </a>
            </li>

            <li className="nav-item"><a className="nav-link">|</a></li>
            
            <li className="nav-item">
              <a
                className = "nav-link"
                id        = {this.active(this.state.musicstate, 2)}
                onClick   = {() => this.setMusicState(2)}
              >
                PLAYLIST
              </a>
            </li>
          </ul>
        </nav>
        {this.renderMusic(this.state.musicstate)}
      </div>
    );
  }
}
export default Music;
