import React from 'react'
import Player from './player'
import ItemPlay from './itemPlay'
export default class PlayList extends React.Component
{
    constructor(props) {
        super(props) 
        this.state = {
            played   : 0,
            duration : 0,
            url      : null,
            data     : [],
            select   : -1,
            playing  : false,
        }
    }

    

    componentDidMount() {
        if(this.props.data) {
            var data = this.props.data.map((item) => {
                return {
                    url      : item.url,
                    title    : item.title,
                    played   : 0,
                    duration : item.duration,
                }
            })
            this.setState({data : data});
        }
        
    }

    ref = player => {
        this.player = player;
    }

    playedChange = (played,index) => {
        this.state.data[index].played = played;
        this.setState({played : played})
    }

    onChangeComplete = (index) => {
        this.state.data[index].played = this.state.played;
        if(index == this.state.select) {
            this.player.seekTo(parseFloat(this.state.played));
        }
    }

    onIconClick = (playing,index) => {
        var select = index;
        this.state.playing = playing;
        if(this.state.select == index && !playing) {
            select = -1;
        }
        this.player.seekTo(parseFloat(this.state.data[index].played));
        this.setState({
            played   : this.state.data[index].played,
            duration : this.state.data[index].duration,
            url      : this.state.data[index].url,
            select   : select,
        })

    }

    renderItemPlayes() {
        if(this.state.data) {
            return this.state.data.map((item,index) => {
                return <ItemPlay 
                    key              = {index}
                    playing          = {index == this.state.select}
                    duration         = {item.duration}
                    played           = {item.played}
                    title            = {item.title}
                    playedChange     = {(played) => this.playedChange(played,index)}
                    onChangeComplete = {() => this.onChangeComplete(index)}
                    onIconClick      = {(playing) => this.onIconClick(playing,index)}
                />
            })
        }
        return null;
    }

    setDuraion(duration) {
        if(this.state.data && this.state.data[this.state.select]) {
            this.state.data[this.state.select].duration = duration;
            this.setState({duration:duration})
        }
    }

    setPlayed(played) {
        if(this.state.data && this.state.data[this.state.select]) {
            this.state.data[this.state.select].played = played;
            this.setState({played:played})
        }
    }

    render() {
        return (
            <div className="playlist-0807 ">
                <div className="item-play-0807">
                    {this.renderItemPlayes()}
                </div>
                <Player  
                    url         = {this.state.url}
                    playing     = {this.state.playing}
                    setDuraion  = {(duration) => this.setDuraion(duration)}
                    reff        = {this.ref}
                    setPlayed   = {(played) => this.setPlayed(played)}
                />
            </div>
        )
    }
}